import { render, staticRenderFns } from "./FooterMenu.vue?vue&type=template&id=694544a5&scoped=true&"
import script from "./FooterMenu.vue?vue&type=script&lang=js&"
export * from "./FooterMenu.vue?vue&type=script&lang=js&"
import style0 from "./FooterMenu.vue?vue&type=style&index=0&id=694544a5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "694544a5",
  null
  
)

export default component.exports